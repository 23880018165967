<script setup lang="ts">
import type { ILeaseReportTemplate } from '@portfolio'
import {
  TEMPLATE_CUSTOM,
  useTemplates,
} from '@reports/components/Template/composables/useTemplates'

interface Props {
  divisionId: string
  isExportingXlsx?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isExportingXlsx: false,
})

const emit = defineEmits<{
  customize: [template: ILeaseReportTemplate]
  download: [template: ILeaseReportTemplate]
}>()

/* === TEMPLATES === */
const { templates, isLoading } = useTemplates()
/* === TEMPLATES === */

/* === VIEW ===*/
const query = useRouteQuery()
const viewURI = (template: ILeaseReportTemplate) => {
  const isForecast = template.name?.includes('Forecast')

  if (isForecast)
    return {
      path: `/division/${props.divisionId}/reports/view/forecast`,
    }

  return {
    path: `/division/${props.divisionId}/reports/view`,
    query: {
      ...query.value,
      template: template.id,
      filters: {
        ...query.value.filters,
        sectors: template.sector ? [template.sector] : undefined,
      },
    },
  }
}

/* === VIEW ===*/

/* === LISTENERS ===*/
const onCustomizeTemplate = (template: ILeaseReportTemplate) =>
  emit('customize', template)
const onDownloadTemplate = (template: ILeaseReportTemplate) =>
  emit('download', template)
/* === LISTENERS ===*/
</script>

<template>
  <div>
    <!-- Loading -->
    <div v-if="isLoading" class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
      <Skeleton v-for="row in [0, 1, 2, 3]" :key="row" height="72px" />
    </div>
    <div v-else class="mt-8 grid grid-cols-1 gap-6 md:grid-cols-2">
      <NuxtLink
        v-for="template in templates"
        :key="template.id"
        :to="viewURI(template)"
        class="hover:border-primary hover:bg-primary/5 focus:border-primary focus:bg-primary/5 grid rounded-lg border border-gray-700 bg-gray-800 p-4 focus:outline-none"
      >
        <h2 class="flex justify-between">
          <span>
            {{ template.name }}
          </span>
          <ReportsTemplateMenu
            :division-id="divisionId"
            :to="viewURI(template)"
            :template="template"
            :is-exporting-xlsx="isExportingXlsx"
            @download="onDownloadTemplate(template)"
            @customize="onCustomizeTemplate(template)"
          />
        </h2>
        <p v-if="template.description">{{ template.description }}</p>
      </NuxtLink>

      <!-- TODO: API to accept to save template -->
      <!-- <Button
        color="secondary"
        class="grid cursor-pointer border border-dashed border-gray-600"
        @click="emit('customize', TEMPLATE_CUSTOM)"
      >
        <h2 class="flex w-full justify-center self-center">
          <Icon name="plus" class="flex self-center" />
          <span> Add custom template </span>
        </h2>
      </Button> -->
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.accurait-table {
  @apply transition-all;
  tr:hover {
    .delete-button {
      @apply opacity-100;
    }
  }

  :deep(tr) {
    &:hover {
      td {
        @apply !bg-gray-750;
      }
    }

    td {
      @apply !border-gray-750;
    }

    td,
    th {
      @apply bg-gray-800;
    }
  }
}
</style>
